'use client'

import InputAdornment from '@mui/material/InputAdornment'
import { StyledFormTextfield } from './FormTextInput.style'
import { TFormTextInput } from './FormTextInput'

export default function FormTextInput({
   startAdornment,
   endAdornment,
   isError,
   helperText,
   isFullwidth,
   isLabelShrink,
   ...props
}: TFormTextInput) {
   return (
      <StyledFormTextfield
         InputProps={{
            disableUnderline: true, // ::TODO:: Throwing warning need to revisit
            ...(startAdornment && {
               startAdornment: <InputAdornment position="start">{startAdornment}</InputAdornment>,
            }),
            ...(endAdornment && {
               endAdornment: <InputAdornment position="end">{endAdornment}</InputAdornment>,
            }),
         }}
         InputLabelProps={{
            ...(isLabelShrink && { shrink: true }),
         }}
         {...(isFullwidth && { fullWidth: true })}
         {...(isError && { error: true })}
         {...(helperText && { helperText })}
         {...props}
      />
   )
}
