'use client'

// react core
import { useState, FormEvent, useEffect } from 'react'

// formik core
import { useFormik } from 'formik'

// store
import {
   useSelector,
   fetchCapraConfigAsync,
   useDispatch,
   useLazyFetchGeoAvailabilityQuery,
   selectConfig,
} from '@/store/redux'

// mui core
import {
   StyledContainer,
   StyledForm,
   StyledFormInput,
   StyledSubmitContainer,
   StyledSubmit,
} from './AuthoriseForm.style'

// types
import { AuthoriseProps } from './AuthoriseForm'

export default function Authorise({}: AuthoriseProps) {
   const dispatch = useDispatch()
   const config = useSelector(selectConfig)

   const [isSubmitDisabled, setSubmitDisabled] = useState<boolean>(false)

   useEffect(() => {
      dispatch(fetchCapraConfigAsync())
   }, [])

   const [triggerGeoAvailablity, { data: geoAvailablityResponse, isFetching: isGeoAvailablityFetching }] =
      useLazyFetchGeoAvailabilityQuery()

   useEffect(() => {
      if (config) {
         // To test error scneario, switch geo id to 6354875098112 manually
         triggerGeoAvailablity({ assetId: config?.geo_test_id })
      }
   }, [config])

   useEffect(() => {
      onSignoutHandler()
   }, [])

   const onSignoutHandler = async () => {
      await fetch('/api/account/logout', { method: 'POST' })
   }

   const formik = useFormik({
      initialValues: {
         username: '',
         userpassword: '',
      },
      onSubmit: async (values) => {
         setSubmitDisabled(true)
         const authoriseFormData = {
            username: values.username,
            userpassword: values.userpassword,
         }

         const response = await fetch('/api/account/authorise', {
            method: 'POST',
            body: JSON.stringify(authoriseFormData),
         })
            .then((data) => data.json())
            .catch((error) => error)
            .finally(() => {
               setSubmitDisabled(false)
            })

         if (response.success === true) {
            if (geoAvailablityResponse) {
               window.location.href = '/signin'
            } else {
               window.location.href = '/unavailable'
            }
         }
      },
   })

   return (
      <StyledContainer>
         <StyledForm
            onSubmit={(e: FormEvent<HTMLFormElement>) => {
               formik.handleSubmit(e)
            }}
            noValidate
         >
            <StyledFormInput
               data-id="fieldUsername"
               variant="filled"
               margin="normal"
               placeholder="Username"
               type="text"
               id="username"
               name="username"
               isFullwidth
               autoComplete="off"
               inputProps={{
                  form: {
                     autocomplete: 'off',
                  },
               }}
               onChange={(e: any) => {
                  formik.handleChange(e)
               }}
               value={formik.values.username}
               required
            />

            <StyledFormInput
               data-id="fieldPassword"
               variant="filled"
               margin="normal"
               placeholder="Password"
               type="password"
               id="userpassword"
               name="userpassword"
               isFullwidth
               onChange={(e: any) => {
                  formik.handleChange(e)
               }}
               value={formik.values.userpassword}
               required
            />

            <StyledSubmitContainer>
               <StyledSubmit
                  type="submit"
                  data-id="ctaSignIn"
                  variant="outro"
                  disabled={isSubmitDisabled || isGeoAvailablityFetching}
               >
                  Authorise
               </StyledSubmit>
            </StyledSubmitContainer>
         </StyledForm>
      </StyledContainer>
   )
}
