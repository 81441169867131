'use client'

// react core
import { forwardRef } from 'react'

// types
import { ButtonProps } from './Button'

// styles
import { StyleButton } from './Button.style'

export default forwardRef<HTMLButtonElement, ButtonProps>(function Button({ onClick, children, ...props }, ref) {
   return (
      <StyleButton onClick={onClick} {...props} ref={ref}>
         {children}
      </StyleButton>
   )
})
