// mui core
import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'

// components
import FormTextInput from '@/app/_component/molecule/FormTextInput'
import Button from '@/app/_component/atom/Button'

// fonts
import { MarkProBoldWoff2 } from '@/ThemeRegistry/theme'

// types
import { TFormInput, TStyledContainer, TStyledForm } from './AuthoriseForm'

export const StyledContainer = styled(Box)<TStyledContainer>(({}) => ({
   maxWidth: 400,
   height: 'auto',
   margin: '0px auto',
   padding: '200px 0px 80px',
   textAlign: 'center',
}))

export const StyledForm = styled('form')<TStyledForm>(({}) => ({}))

export const StyledFormInput = styled(FormTextInput)<TFormInput>(({ theme, isformerror }) => ({
   '&.MuiFormControl-root': {
      marginTop: 16,
      marginBottom: 8,

      ...(isformerror === 'true' && {
         marginBottom: 0,
      }),

      '.MuiInputBase-root': {
         height: 56,
         background: 'transparent',
         borderRadius: 4,
         border: `1px solid #969696`,
      },

      '.MuiInputBase-input': {
         paddingTop: 8,
         paddingRight: 14,
         paddingBottom: 8,
         paddingLeft: 14,
         color: theme.palette.common.black,
         fontSize: 16,
      },

      '.MuiInputLabel-root': {
         fontSize: 18,
         letterSpacing: 'normal',
         color: '#969696',
         transition: `color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
                transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
                max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
                font-size 200ms cubic-bezier(0, 0, 0.2, 1) 0ms`,

         '&.MuiInputLabel-shrink': {
            transform: 'translate(14px, 9px) scale(1)',
            fontSize: 12,
            color: '#969696',
            opacity: 0.75,
         },
      },
   },
}))

export const StyledSubmitContainer = styled(Box)(({}) => ({
   width: '100%',
   marginTop: 16,
}))

export const StyledSubmit = styled(Button)(({ theme }) => ({
   borderRadius: 5,
   backgroundColor: '#969696',
   color: theme.palette.common.white,
   fontFamily: MarkProBoldWoff2.style.fontFamily,
   fontSize: 14,
   fontWeight: 700,
   lineHeight: '15.4px',
   letterSpacing: '1.2px',
   textTransform: 'uppercase',
   padding: '18px 14px',
   margin: '0 auto',
   '&:hover': {
      backgroundColor: '#969696',
      color: theme.palette.common.white,
   },
}))
